<template>
  <div className="animated">
    <div class="container">
      <b-row class="justify-content-center">
            <div id="snackbar"></div>
        <b-col md="6" sm="8">
          <b-card no-body class="mx-1">
            <b-card-body class="p-4">
              <b-form v-on:submit.prevent.self="submit">
                <!-- <h1>Register</h1> -->
                <p class="text-muted">Add Showbizz</p>
                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-address-book-o"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" required v-model="showbizz.OrganizationName" class="form-control" placeholder="OrganizationName" autocomplete="OrganizationName" />
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" required v-model="showbizz.UserName" class="form-control" placeholder="Username" autocomplete="username" />
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="password" required v-model="showbizz.Password" class="form-control" placeholder="Password" autocomplete="password" />
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-user-o"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-select v-model="showbizz.CountryID" id="country"
                  :plain="true"
                  value-field="CountryID"
                  text-field="CountryName"
                  :options=countries>
                  <template slot="first">
                    <option :value="0" disabled>-- Select Country --</option>
                  </template>
                </b-form-select>
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" required v-model="showbizz.ScheduleURL" class="form-control" placeholder="Schedule URL" autocomplete="schedule-url" />
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" required v-model="showbizz.SeatBookURL" class="form-control" placeholder="Seat Book URL" autocomplete="seatbook-url" />
                </b-input-group>
                <b-button type="submit" variant="success" block>{{ commontext.submitButton }}</b-button>
                <b-button @click="resetForm" variant="success" block>Reset</b-button>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col sm="12">

         <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>Showbizz List
        <div class="card-header-actions">
          
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
          
         <div slot="ShowbizzID" slot-scope="props">
            <i class="fa fa-edit" @click="editShowbizz(props.row.ShowbizzID)"></i>
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>
        </b-col>
      </b-row>
    </div>
    <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
         <div class="d-block text-center">
           <div>{{ modalObject.message }}</div>
         </div>
       </b-modal>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import UserService from "@/services/UserService";
import { Event } from "vue-tables-2";
import MasterService from "@/services/MasterService";

export default {
  name: "Showbizz",
  components: {
    cSwitch,
    Event
  },
  data() {
    return {
      commontext: {
        submitButton: "Add Showbizz"
      },
      showbizz: {
        Flag: 1,
        ShowbizzID: null,
        OrganizationName: "",
        UserName: "",
        Password: "",
        CountryID: 0,
        ScheduleURL: "",
        SeatBookURL: ""
      },
      countries: [],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      columns: ["OrganizationName", "ShowbizzID"],
      data: [],
      options: {
        headings: {
          OrganizationName: "Organization",
          UserName: "User",
          ShowbizzID: "Edit"
        },
        sortable: ["OrganizationName"],
        filterable: ["OrganizationName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getShowbizzList();
    this.getCountriesDDL();
  },
  methods: {
    resetForm: function() {
      this.showbizz = {
        ShowbizzID: null,
        OrganizationName: "",
        Flag: 1,
        UserName: "",
        Password: "",
        ScheduleURL: "",
        SeatBookURL: "",
        CountryID: 0
      };
      this.commontext.submitButton = "Add Showbizz";
    },
    getCountriesDDL: function() {
      // let countries = await MasterService.getCountriesDDL();
      MasterService.getCountriesDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.countries = data.CountryList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getShowbizzList: function() {
      // let res = await MasterService.getShowbizzList();
      // this.data = res.data;
      this.data = [];
      MasterService.getShowbizzList()
        .then(response => {
          const { data } = response;
          if (data.Status && data.ShowbizzList.length > 0) {
            this.data = data.ShowbizzList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.showbizz.OrganizationName == "") {
        this.showMessageSnackbar("Organization Name Cannot be empty.");
        return false;
      } else if (this.showbizz.UserName == "") {
        this.showMessageSnackbar("User Name Cannot be empty.");
        return false;
      } else if (this.showbizz.Password == "") {
        this.showMessageSnackbar("Password Cannot be empty.");
        return false;
      } else if (this.showbizz.CountryID == 0) {
        this.showMessageSnackbar("Please select the Country.");
        return false;
      } else if (this.showbizz.ScheduleURL == "") {
        this.showMessageSnackbar("Schedule URL Cannot be empty.");
        return false;
      } else if (this.showbizz.SeatBookURL == "") {
        this.showMessageSnackbar("Seat Book URL Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },

    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");
      MasterService.addShowbizz(this.showbizz)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.resetForm();
            this.getShowbizzList();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editShowbizz: function(ShowbizzID) {
      let payload = {
        ShowbizzID
      };
      MasterService.getShowbizzByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.ShowbizzList.length > 0) {
            this.commontext.submitButton = "Update Showbizz";
            this.showbizz = data.ShowbizzList[0];
            this.showbizz.Flag = 2;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.form-control {
  border-radius: 0px;
}
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
